import { SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import useTableMode from '@app/core/source/hooks/useTableMode';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import { LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';
import usePagination from '@app/core/source/hooks/pagination/usePagination';
import { TableMode } from '@app/v2/shared/enums';

export default function useListingPage(loading: boolean) {
  const [tableMode, setTableMode] = useTableMode();
  const [paginationSearch, setPaginationSearch] = usePaginationSearch();
  const [pagination, setPagination] = usePagination();

  const isUpdateDataPagination = useRef<boolean>(false);
  const prevIndex = useRef<number>(0);

  useEffect(() => {
    isUpdateDataPagination.current = loading;
  }, [loading]);

  const handleUpdatePagination = useCallback(
    (value: number, field: keyof Common.Pagination) => {
      setPaginationSearch(prev => ({ ...prev, [field]: value }));
    },
    [setPaginationSearch],
  );

  const changePage = useCallback(() => {
    if (pagination.total >= pagination.limit * pagination.page) {
      const newPage = pagination.page + 1;
      setPagination({ ...pagination, page: newPage });
      setPaginationSearch(prev => ({ ...prev, page: newPage }));
    } else {
      isUpdateDataPagination.current = false;
    }
  }, [setPagination, setPaginationSearch, pagination]);

  const handleScroll = useCallback(
    ({ currentTarget: { scrollHeight, scrollTop, clientHeight } }: SyntheticEvent) => {
      if (scrollHeight - Math.ceil(scrollTop) <= clientHeight && !isUpdateDataPagination.current) {
        isUpdateDataPagination.current = true;
        changePage();
      }
    },
    [changePage],
  );

  const handleVirtualizedScroll = useCallback(
    (index: number) => {
      if (prevIndex.current === index) return;

      prevIndex.current = index;
      changePage();
    },
    [changePage],
  );

  const handleNextPage = useCallback(() => {
    changePage();
  }, [changePage]);

  const handleChangeTableMode = useCallback(
    currentTableMode => {
      if (currentTableMode === TableMode.Pagination) {
        setTableMode(currentTableMode, paginationSearch);
      } else {
        setTableMode(currentTableMode, { ...paginationSearch, page: LISTING_DEFAULT_PAGE_NUMBER });
        setPaginationSearch({ ...paginationSearch, page: LISTING_DEFAULT_PAGE_NUMBER });
      }
    },
    [setTableMode, paginationSearch, setPaginationSearch],
  );

  return {
    pagination: paginationSearch,
    tableMode,
    handleUpdatePagination,
    handleScroll,
    handleChangeTableMode,
    handleNextPage,
    handleVirtualizedScroll,
  };
}
