import React, { useState, useCallback, useMemo } from 'react';
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';

type Option = {
  label: string;
  onClick: (index: number) => void;
};

interface Props {
  disabled?: boolean;
  options: Option[];
  initialOptionIndex?: number;
}

const CSDSplitButton = ({ options, initialOptionIndex = 0, disabled = false }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(initialOptionIndex);

  const { onClick: optionAction, label: optionLabel } = useMemo(() => options[selectedIndex], [selectedIndex, options]);

  const handleClick = useCallback(() => {
    optionAction(selectedIndex);
  }, [selectedIndex, optionAction]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleMenuClick = useCallback((index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  }, []);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup className={classes.buttonGroup} variant="contained" ref={anchorRef}>
        <Button disabled={disabled} onClick={handleClick}>
          {optionLabel}
        </Button>
        <Button
          disabled={disabled}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleToggle}
        >
          <Icon className={classes.openIcon}>{icons.down}</Icon>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1, width: anchorRef?.current?.clientWidth || 'auto' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map(({ label }, index) => (
                    <MenuItem key={label} selected={index === selectedIndex} onClick={() => handleMenuClick(index)}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    '&.MuiPaper-root': {
      background: 'white',
    },
  },
  buttonGroup: {
    '&.MuiButtonGroup-root': {
      width: 'max-content',
      borderRadius: '0.5rem',
    },
  },
  openIcon: {
    '&.MuiIcon-root': {
      fontSize: '1.5rem !important',
    },
  },
}));

export default CSDSplitButton;
