const getMinMaxRoadLength = (roadCode: string, roads: Journals.ExcelRoad[]) => {
  const roadData = roads.find(({ value }) => value === roadCode);
  const [minKm, minM] = roadData?.length?.addressStart?.split('+') || [];
  const [maxKm, maxM] = roadData?.length?.addressEnd?.split('+') || [];

  return {
    minKm,
    minM,
    maxKm,
    maxM,
  };
};

export default getMinMaxRoadLength;
