import { lazy } from 'react';
import { MainPaths } from '@app/v2/shared/enums';

const IndexModule = lazy(() => import(/* webpackChunkName: 'index' */ '@app/modules/index-module'));
const VideoWallModule = lazy(() => import(/* webpackChunkName: 'video' */ '@app/modules/video-wall-module'));
const VideoWaterLevelControlModule = lazy(
  () => import(/* webpackChunkName: 'videoWaterLevelControl' */ '@app/modules/video-water-level-control-module'),
);
const MapModule = lazy(() => import(/* webpackChunkName: 'map' */ '@app/modules/map-module'));
const TrafficModule = lazy(() => import(/* webpackChunkName: 'traffic' */ '@app/modules/traffic-module'));
const MeteoModule = lazy(() => import(/* webpackChunkName: 'meteo' */ '@app/modules/meteo-module'));
const LocatorAnalysisModule = lazy(() => import(/* webpackChunkName: 'locatorAnalysis' */ '@app/modules/locator-analysis-module'));
const FavoritesModule = lazy(() => import(/* webpackChunkName: 'favorites' */ '@app/modules/favorites-module'));
const MapNotes = lazy(() => import(/* webpackChunkName: 'mapNotes' */ '@app/modules/map-module/components/map-notes-listing'));
const NotificationModule = lazy(() => import(/* webpackChunkName: 'notifications' */ '@app/modules/notification-module'));
const SOPSModule = lazy(() => import(/* webpackChunkName: 'SOPS' */ '@app/modules/sops-module'));
const ForecastModule = lazy(() => import(/* webpackChunkName: 'forecast' */ '@app/modules/gidromed-module'));
const SoilFreezingModule = lazy(() => import(/* webpackChunkName: 'soilFreezing' */ '@app/modules/soil-freezing-module'));
const ECOMonitoringModule = lazy(() => import(/* webpackChunkName: 'ECOMonitoring' */ '@app/modules/eco-monitoring-module'));
const StationDetailsModule = lazy(() => import(/* webpackChunkName: 'StationDetails' */ '@app/modules/station-details-module'));
const FederalRoadsSegmentsModule = lazy(() => import(/* webpackChunkName: 'FederalRoads' */ '@app/modules/federal-roads-module/roads'));
const AccidentNewsModule = lazy(() => import(/* webpackChunkName: 'News' */ '@app/modules/accident-news-module'));
const IncidentModule = lazy(() => import(/* webpackChunkName: 'News' */ '@app/modules/video-accidents-module'));
const MonitorModule = lazy(() => import(/* webpackChunkName: 'News' */ '@app/modules/monitor-module/dispatcher-page'));
const SituationCenterJournalModule = lazy(
  () => import(/* webpackChunkName: 'SituationCenterJournalModule' */ '@app/modules/monitor-module/situation-center-journal'),
);
const SituationCenterModule = lazy(() => import(/* webpackChunkName: 'SituationCenterModule' */ '@app/modules/monitor-module/situation-center'));
const RoadManagementOrgan = lazy(() => import(/* webpackChunkName: 'News' */ '@app/modules/federal-roads-module/regional-road-organ'));

/** Reports * */

const ReportsModule = lazy(() => import(/* webpackChunkName: 'ReportsModule' */ '@app/modules/reports-module'));

/** Managements * */

const MeteoManagement = lazy(() => import(/* webpackChunkName: 'meteoManagement' */ '@app/modules/services-module/containers/meteoManagement'));
const TrafficManagement = lazy(() => import(/* webpackChunkName: 'trafficManagement' */ '@app/modules/services-module/containers/trafficManagement'));
const VideoManagement = lazy(() => import(/* webpackChunkName: 'videoManagement' */ '@app/modules/services-module/containers/videoManagement'));

/** Guides * */

const GuideOrganizations = lazy(() => import(/* guidOrganizations */ '@app/modules/guide-module/organizationsGuide'));
const GuideUsers = lazy(() => import(/* guidUsers */ '@app/modules/guide-module/usersGuide'));
const GuideRoads = lazy(() => import(/* guidRoads */ '@app/modules/guide-module/roadsGuide'));

/** Scoreboard * */

const Configuration = lazy(() => import(/* configuration */ '@app/modules/scoreboard-module/configuration'));
const Devices = lazy(() => import(/* devices */ '@app/modules/scoreboard-module/devices'));
const Templates = lazy(() => import(/* templates */ '@app/modules/scoreboard-module/templates'));
const Signs = lazy(() => import(/* signs */ '@app/modules/scoreboard-module/signs'));
const Playlists = lazy(() => import(/* playlists */ '@app/modules/scoreboard-module/playlists'));
const SingsAndScoreboard = lazy(() => import(/* webpackChunkName: 'singsAndScoreboard' */ '@app/modules/scoreboard-module/signs-and-scoreboards'));

/** Logs */

const LogAccident = lazy(() => import(/* logAccident */ '@app/modules/log-module/containers/accident'));
const LogDuty = lazy(() => import(/* logDuty */ '@app/modules/log-module/containers/duty'));
const LogESFacility = lazy(() => import(/* logESFacility */ '@app/modules/log-module/containers/emergency-situation-facility'));
const LogESBuilding = lazy(() => import(/* logESBuilding */ '@app/modules/log-module/containers/emergency-situation-building'));
const LogEmergencySituation = lazy(() => import(/* logEmergencySituation */ '@app/modules/log-module/containers/emergency-situation'));
const LogBreaks = lazy(() => import(/* logBreaks */ '@app/modules/log-module/containers/breaks'));
const LogTrafficRestriction = lazy(() => import(/* logTrafficRestriction */ '@app/modules/log-module/containers/traffic-restriction'));
const LogForcesAttracting = lazy(() => import(/* logForcesAttracting */ '@app/modules/log-module/containers/forces-attracting'));
const LogFloodEffects = lazy(() => import(/* logFloodEffects */ '@app/modules/log-module/containers/flood-effects'));
const LogHazard = lazy(() => import(/* logHazard */ '@app/modules/log-module/containers/hazard'));

/** Dashboard */

const Dashboard = lazy(() => import(/* dashboard */ '@app/modules/dashboard'));

const routesConfig: StaticConfigs.RouteItem[] = [
  {
    label: 'index',
    path: MainPaths.Index,
    name: 'index',
    component: IndexModule,
  },
  {
    label: 'meteo',
    path: MainPaths.Meteo,
    name: 'meteo',
    component: MeteoModule,
  },
  {
    label: 'stationDetails',
    path: MainPaths.StationDetails,
    name: 'station_details',
    component: StationDetailsModule,
  },
  {
    label: 'locatorAnalysis',
    path: MainPaths.LocatorAnalysis,
    name: 'locator_analyze',
    component: LocatorAnalysisModule,
  },
  {
    label: 'video',
    path: MainPaths.Video,
    name: 'video',
    component: VideoWallModule,
  },
  {
    label: 'videoWaterLevelControl',
    path: MainPaths.VideoWaterLevelControl,
    name: 'video_water_level_control',
    component: VideoWaterLevelControlModule,
  },
  {
    label: 'mapNotes',
    path: MainPaths.MapNotes,
    name: 'map_notes',
    component: MapNotes,
  },
  {
    label: 'traffic',
    path: MainPaths.Traffic,
    name: 'traffic',
    component: TrafficModule,
  },
  {
    label: 'management',
    path: MainPaths.MeteoManagement,
    name: 'meteo_management',
    component: MeteoManagement,
  },
  {
    label: 'management',
    path: MainPaths.TrafficManagement,
    name: 'traffic_management',
    component: TrafficManagement,
  },
  {
    label: 'management',
    path: MainPaths.VideoManagement,
    name: 'video_management',
    component: VideoManagement,
  },
  {
    label: 'map',
    path: MainPaths.Map,
    name: 'map',
    component: MapModule,
  },
  {
    label: 'configuration',
    path: MainPaths.ScoreboardConfiguration,
    name: 'configuration',
    component: Configuration,
  },
  {
    label: 'devices',
    path: MainPaths.ScoreboardDevices,
    name: 'devices',
    component: Devices,
  },
  {
    label: 'scoreboard',
    path: MainPaths.ScoreboardTemplates,
    name: 'scoreboard',
    component: Templates,
  },
  {
    label: 'signs',
    path: MainPaths.ScoreboardSigns,
    name: 'signs',
    component: Signs,
  },
  {
    label: 'playlists',
    path: MainPaths.ScoreboardPlaylists,
    name: 'playlists',
    component: Playlists,
  },
  {
    label: 'scoreboardsAndSignsV2',
    path: MainPaths.ScoreboardsAndSigns,
    name: 'scoreboards_and_signs_v2',
    component: SingsAndScoreboard,
  },
  {
    label: 'favorites',
    path: MainPaths.Favorites,
    name: 'favorites',
    component: FavoritesModule,
  },
  {
    label: 'accident',
    path: MainPaths.LogAccident,
    name: 'accident',
    component: LogAccident,
  },
  {
    label: 'duty',
    path: MainPaths.LogDuty,
    name: 'duty',
    component: LogDuty,
  },
  {
    label: 'emergencySituationFacility',
    path: MainPaths.LogEmergencySituationFacility,
    name: 'emergency-situation-facility',
    component: LogESFacility,
  },
  {
    label: 'emergencySituationBuilding',
    path: MainPaths.LogEmergencySituationBuilding,
    name: 'emergency-situation-building',
    component: LogESBuilding,
  },
  {
    label: 'emergencySituation',
    path: MainPaths.LogEmergencySituation,
    name: 'emergency-situation',
    component: LogEmergencySituation,
  },
  {
    label: 'breaks',
    path: MainPaths.LogBreaks,
    name: 'breaks',
    component: LogBreaks,
  },
  {
    label: 'trafficRestriction',
    path: MainPaths.LogTrafficRestriction,
    name: 'traffic-restriction',
    component: LogTrafficRestriction,
  },
  {
    label: 'forcesAttracting',
    path: MainPaths.LogForcesAttracting,
    name: 'forces-attracting',
    component: LogForcesAttracting,
  },
  {
    label: 'floodEffects',
    path: MainPaths.LogFloodEffects,
    name: 'flood-effects',
    component: LogFloodEffects,
  },
  {
    label: 'dutyForecast',
    path: MainPaths.LogHazard,
    name: 'duty',
    component: LogHazard,
  },
  {
    label: 'guidesOrganizations',
    path: MainPaths.GuidesOrganizations,
    name: 'guides',
    component: GuideOrganizations,
  },
  {
    label: 'guidesUsers',
    path: MainPaths.GuidesUsers,
    name: 'guides',
    component: GuideUsers,
  },
  {
    label: 'guidesRoads',
    path: MainPaths.GuidesRoads,
    name: 'guides',
    component: GuideRoads,
  },
  {
    label: 'notifications',
    path: MainPaths.Notifications,
    name: 'notifications',
    component: NotificationModule,
  },
  {
    label: 'cityWeatherForecast',
    path: MainPaths.CityWeatherForecast,
    name: 'cityWeatherForecast',
    component: ForecastModule,
  },
  {
    label: 'SOPS',
    path: MainPaths.SOPS,
    name: 'SOPS',
    component: SOPSModule,
  },
  {
    label: 'adaptationToClimateChange',
    path: MainPaths.AdaptationToClimateChange,
    name: 'adaptationToClimateChange',
    component: SoilFreezingModule,
  },
  {
    label: 'ecoMonitoring',
    path: MainPaths.ECOMonitoring,
    name: 'ecoMonitoring',
    component: ECOMonitoringModule,
  },
  {
    label: 'reports',
    path: MainPaths.Reports,
    name: 'reports',
    component: ReportsModule,
  },
  {
    label: 'accidentNews',
    path: MainPaths.AccidentNews,
    name: 'accidentNews',
    component: AccidentNewsModule,
  },
  {
    label: 'incident',
    path: MainPaths.Incidents,
    name: 'incident',
    component: IncidentModule,
  },
  {
    label: 'federalRoads',
    path: MainPaths.FederalRoads,
    name: 'federalRoads',
    component: FederalRoadsSegmentsModule,
  },
  {
    label: 'dashboard',
    path: MainPaths.Dashboard,
    name: 'dashboard',
    component: Dashboard,
  },
  {
    label: 'monitor',
    path: MainPaths.Monitor,
    name: 'monitor',
    component: MonitorModule,
  },
  {
    label: 'situationCenterJournal',
    path: MainPaths.SituationCenterJournal,
    name: 'situationCenterJournal',
    component: SituationCenterJournalModule,
  },
  {
    label: 'situationCenter',
    path: MainPaths.SituationCenter,
    name: 'situationCenter',
    component: SituationCenterModule,
  },
  {
    label: 'roadManagementOrgan',
    path: MainPaths.RoadManagementOrgan,
    name: 'roadManagementOrgan',
    component: RoadManagementOrgan,
  },
];

export default routesConfig;
