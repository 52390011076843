import { JournalReportWidgetTypes, FileExtensions } from '@app/v2/shared/enums';

export const DEFAULT_JOURNAL_WIDGET_SETTINGS = {
  [JournalReportWidgetTypes.TrafficAccidents]: {
    order: 0,
    size: 2,
  },
  [JournalReportWidgetTypes.InterruptionReports]: {
    order: 1,
    size: 1,
  },
  [JournalReportWidgetTypes.RestrictionReports]: {
    order: 2,
    size: 1,
  },
  [JournalReportWidgetTypes.NewAccidentReports]: {
    order: 3,
    size: 1,
  },
  [JournalReportWidgetTypes.OccurrenceReport]: {
    order: 4,
    size: 1,
  },
  [JournalReportWidgetTypes.ForecastReports]: {
    order: 5,
    size: 1,
  },
  [JournalReportWidgetTypes.EmergencyReports]: {
    order: 6,
    size: 1,
  },
  [JournalReportWidgetTypes.EquipmentStatistic]: {
    order: 7,
    size: 2,
  },
};

export const GRID_COLUMNS = 4;

export const REPORT_DAY_SHIFT = 1;

export const DEFAULT_MIN_COLUMN_SIZE = 1;
export const DEFAULT_TRAFFIC_ACCIDENT_MIN_COLUMN_SIZE = 2;
export const DEFAULT_EQUIPMENT_STATISTIC_MIN_COLUMN_SIZE = 2;

export const DEFAULT_DATE_HOURS = 8;
export const DEFAULT_DATE_MINUTES = 0;
export const DEFAULT_DATE_SECONDS = 0;

export const PREV_START_END_DATE_HOURS = 7;
export const MAX_START_SESSION_MINUTES = 55;
export const NOTIFICATION_MINUTES = [0, 30, 55];

export const MAX_KM_M_INPUT_LENGTH = 4;

export const SEND_SESSIONS_HOURS_DIFF = 22;

export const ONLY_DIGITS_REGEX = /[^0-9]/g;

const VIDEO_POSTFIX = 'video/';
const IMAGE_POSTFIX = 'photo/';

export const IMAGE_UPLOAD_URL = process.env.REACT_APP_ACCIDENT_REPORT_FILES_UPLOAD_URL + IMAGE_POSTFIX;

export const VIDEO_UPLOAD_URL = process.env.REACT_APP_ACCIDENT_REPORT_FILES_UPLOAD_URL + VIDEO_POSTFIX;

export const MOSCOW_TIMEZONE = 'Europe/Moscow';

export const FILE_MIME_TYPES = {
  [FileExtensions.PDF]: 'application/pdf',
  [FileExtensions.DOCX]: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};
