export enum JournalReportWidgetTypes {
  TrafficAccidents = 'trafficAccidents',
  RestrictionReports = 'restrictionReports',
  InterruptionReports = 'interruptionReports',
  EmergencyReports = 'emergencyReports',
  NewAccidentReports = 'newAccidentReports',
  EquipmentStatistic = 'equipmentStatistic',
  ForecastReports = 'forecastReports',
  OccurrenceReport = 'occurrenceReport',
}

export enum ReportStatuses {
  Draft = 'draft',
  Prepared = 'prepared',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum RestrictionReportTypes {
  Interruption = 'interruption',
  Restriction = 'restriction',
}

export enum AccidentFormTypes {
  TrafficAccidentReport = 'trafficAccidentReport',
  RestrictionReport = 'restrictionReport',
  EmergencyReport = 'emergencyReport',
  NewAccidentReport = 'newAccidentReport',
  OccurrenceReport = 'occurrenceReport',
  NoticeForecastReport = 'noticeForecastReport',
}

export enum JournalReportFileTypes {
  DailyReport = 'dailyReport',
  TrafficAccidentReport = 'trafficAccidentReport',
  AccidentReport = 'accidentReport',
  RestrictionReport = 'restrictionReport',
  TrafficInterruptionReport = 'trafficInterruptionReport',
  EmergencySituationReport = 'emergencySituationReport',
  NoticeForecastReport = 'noticeForecastReport',
  OccurrenceReport = 'occurrenceAccidentReport',
  SummarizedInfoReport = 'summarizedInfoReport',
}

export enum AccidentFormSteps {
  TrafficAccidentFirstStep = 'trafficAccidentFistStep',
  RestrictionReport = 'restrictionReport',
  EmergencyReport = 'emergencyReport',
  NewAccidentReport = 'newAccidentReport',
  TrafficAccidentSecondStep = 'trafficAccidentSecondStep',
  OccurrenceReport = 'occurrenceReport',
  NoticeForecastReport = 'noticeForecastReport',
}

export enum RestrictionTypes {
  Start = 'Н',
  End = 'К',
}

export enum JournalDnDSettingsTypes {
  Position = 'position',
  Size = 'size',
}

// Reports keys

export enum BaseReportKeys {
  Id = 'id',
  Status = 'status',
  Description = 'description',
  Comment = 'comment',
  DailyReportId = 'dailyReportId',
  TrafficReportId = 'trafficReportId',
  JournalUser = 'journalUser',
}

export enum TrafficAccidentKeys {
  City = 'city',
  Code = 'code',
  CountAccident = 'countAccident',
  CountChildren = 'countChildren',
  Conditions = 'conditions',
  RoadCode = 'roadCode',
  RoadId = 'roadId',
  AccidentDt = 'accidentDt',
  RoadPartKm = 'roadPartKm',
  RoadPartM = 'roadPartM',
  Restriction = 'restriction',
  AdditionalInfo = 'additionalInfo',
  Video = 'video',
  Photo = 'photo',

  Injured = 'injured',
  InjuredChildren = 'injuredChildren',
  Died = 'died',
  DiedChildren = 'diedChildren',
  Claims = 'claims',
  Damage = 'damage',
  Measures = 'measures',
}

export enum LocationIntervalKeys {
  RoadId = 'roadId',
  RoadCode = 'roadCode',
  AccidentDt = 'accidentDt',
  City = 'city',
  StartRoadPartKm = 'startRoadPartKm',
  StartRoadPartM = 'startRoadPartM',
  EndRoadPartKm = 'endRoadPartKm',
  EndRoadPartM = 'endRoadPartM',
}

export enum RestrictionReportKeys {
  ParentId = 'parentId',
  Type = 'type',
  Reason = 'reason',
  StartDt = 'startDt',
  EndDt = 'endDt',
}

export enum EmergencyReportKeys {
  Criteria = 'criteria',
  AdditionalDescription = 'additionalDescription',
  Measures = 'measures',
}

export enum NewAccidentReportKeys {
  Damage = 'damage',
  Measures = 'measures',
}

export enum OccurrenceReportKeys {
  AccidentDt = 'accidentDt',
  Place = 'place',
  Damage = 'damage',
  Measures = 'measures',
}

export enum MeasuresAccidentKeys {
  Emergency = 'emergency',
  Government = 'government',
  RoadInspection = 'roadInspection',
  Video = 'video',
  Photo = 'photo',
  Channels = 'channels',
}

export enum DialogDataKeys {
  TrafficAccidentData = 'trafficAccidentData',
  RestrictionReportData = 'restrictionReportData',
  EmergencyReportData = 'emergencyReportData',
  NewAccidentReportData = 'newAccidentReportData',
  ForecastReportData = 'forecastReportData',
  OccurrenceReportData = 'occurrenceReportData',
  NoticeForecastReportData = 'noticeForecastReportData',
}

export enum ReviewDataKeys {
  Reviewer = 'reviewer',
  Name = 'name',
  Organization = 'organization',
  Post = 'post',
}

export enum ForecastDataKeys {
  Id = 'id',
  Type = 'type',
  Measures = 'measures',
  AdditionalInfo = 'additionalInfo',
  Description = 'description',
}

export enum NoticeForecastDataKeys {
  Id = 'id',
  AccidentDt = 'accidentDt',
  Status = 'status',
  ForecastReports = 'forecastReports',
}

export enum FileExtensions {
  PDF = 'pdf',
  DOCX = 'docx',
}
