import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { CSDAutocompleteVirtualize } from '@app/modules/kit-module/shared/ui';
import { DataTestIds, FiltersKeys } from '@app/v2/shared/enums';
import { useLayoutConfig } from '@app/v2/shared/hooks';

interface Props extends I18N.TranslationFunction<'filters'> {
  contextFilters: Filters.Filter;
  dictionaries: Filters.Dictionary;
  isDictionaryLoading: boolean;
  setFilter: <Key extends FiltersKeys>(key: Key, value: Partial<Filters.FiltersValues[Key]>['value']) => void;
  multiple?: Partial<Record<FiltersKeys.Places | FiltersKeys.Roads | FiltersKeys.Organizations, boolean>>;
}

const CSDFilterSection = ({ contextFilters, setFilter, multiple = {}, dictionaries, isDictionaryLoading, t }: Props) => {
  const {
    filterConfig: { isDisabledOrganizations, isDisabledRoads, isDisabledPlaces },
  } = useLayoutConfig();

  const onChange = (newValue: Common.SelectorValues, key: FiltersKeys) => {
    const nextValue = multiple?.[key] ? [Number(newValue[newValue.length - 1]?.value)].filter(Boolean) : newValue.map(({ value }) => Number(value));

    setFilter(key, nextValue);
  };

  return (
    <Stack direction="row" className="filterSection" data-testid={DataTestIds.FilterSection}>
      <CSDAutocompleteVirtualize
        id={FiltersKeys.Organizations}
        label={t('autocomplete.org.label')}
        disabled={!dictionaries?.organizations?.length || isDisabledOrganizations}
        showFullLabelTooltip
        inputProps={{
          fullWidth: true,
          placeholder: t('autocomplete.org.placeholder'),
        }}
        autocompleteProps={{
          loading: isDictionaryLoading,
          multiple: true,
          options: dictionaries?.organizations ?? [],
          disableCloseOnSelect: true,
          value: contextFilters?.organizations.value.map(id => ({ value: id })),
          setValue: (_, newValue) => onChange(newValue, FiltersKeys.Organizations),
          dataTestId: DataTestIds.FiltersOrgsAutocomplete,
        }}
      />

      <Divider orientation="vertical" flexItem />

      <CSDAutocompleteVirtualize
        id={FiltersKeys.Roads}
        label={t('autocomplete.roads.label')}
        disabled={!dictionaries?.roads?.length || isDisabledRoads}
        showFullLabelTooltip
        inputProps={{
          placeholder: t('autocomplete.road.placeholder'),
          fullWidth: true,
        }}
        autocompleteProps={{
          loading: isDictionaryLoading,
          options: dictionaries?.roads ?? [],
          value: contextFilters?.roads.value.map(id => ({ value: id })),
          setValue: (_, newValue) => onChange(newValue, FiltersKeys.Roads),
          disableCloseOnSelect: true,
          multiple: true,
          dataTestId: DataTestIds.FiltersRoadsAutocomplete,
        }}
        isShowChipTooltip
      />

      <Divider orientation="vertical" flexItem />

      <CSDAutocompleteVirtualize
        id={FiltersKeys.Places}
        label={t('autocomplete.places.label')}
        disabled={!dictionaries?.places?.length || isDisabledPlaces}
        showFullLabelTooltip
        inputProps={{
          placeholder: t('autocomplete.place.placeholder'),
          fullWidth: true,
        }}
        autocompleteProps={{
          loading: isDictionaryLoading,
          options: dictionaries?.places ?? [],
          value: contextFilters?.places.value.map(id => ({ value: id })),
          setValue: (_, newValue) => onChange(newValue, FiltersKeys.Places),
          disableCloseOnSelect: true,
          multiple: true,
          dataTestId: DataTestIds.FiltersStationsAutocomplete,
        }}
      />
    </Stack>
  );
};

export default withTranslation('filters')(memo(CSDFilterSection));
