/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VIDEO_WALL_DEFAULT_STATE } from '@app/modules/video-wall-module/constants/constants';
import { WATER_LEVEL_CONTROL_DEFAULT_STATE } from '@app/modules/video-water-level-control-module/constants/constants';
import { DEFAULT_JOURNAL_WIDGET_SETTINGS } from '@app/modules/monitor-module/dispatcher-page/constants';
import { ReducersNames } from '@store/constants';
import { AccountSettings, VideoWallSettings, WaterLevelControlSettings, JournalWidgetSetting } from '@store/slices/account/types';
import { ThemeMode } from '@theme/types';

const checkJournalWidgetSettingsValidity = (settings: JournalWidgetSetting) => {
  const settingsOrderSet = new Set(Object.values(settings).map(setting => setting.order));
  return settingsOrderSet.size === Object.values(settings).length;
};

type State = {
  settings: AccountSettings;
  isAccountSettingsExist: boolean;
  isAccountTimeSettingsUpdated: boolean;
};

export const initialState: State = {
  settings: {
    userId: null,
    userName: '',
    userEmail: '',
    userPosition: '',
    userPhone: null,
    userOrganizationId: null,
    userOrganization: '',
    timezoneInsteadCheckbox: false,
    utc: null,
    theme: ThemeMode.light,
    isRemoveFromOrganizationList: false,
    isNotReceiveWeatherReports: true,
    isMeteoAlertPush: true,
    isMeteoBulletinPush: true,
    locale: null,
    startPage: null,
    isServiceEngineer: null,
    isVMSManagement: null,
    isRoot: null,
    isClockDisabled: true,
    permissions: [],
    videoWallSetting: VIDEO_WALL_DEFAULT_STATE,
    waterLevelControlSettings: WATER_LEVEL_CONTROL_DEFAULT_STATE,
    journalWidgetSettings: DEFAULT_JOURNAL_WIDGET_SETTINGS,
  },
  isAccountSettingsExist: false,
  isAccountTimeSettingsUpdated: false,
};

const accountSlice = createSlice({
  name: ReducersNames.account,
  initialState,
  reducers: {
    setAccountSettings(state: State, { payload }: PayloadAction<{ accountData: AccountSettings }>) {
      const newWidgetSettings = {
        ...state.settings.journalWidgetSettings,
        ...payload.accountData.journalWidgetSettings,
      };

      state.settings = {
        ...state.settings,
        ...payload.accountData,
        videoWallSetting: {
          ...state.settings.videoWallSetting,
          ...payload.accountData.videoWallSetting,
        },
        waterLevelControlSettings: {
          ...state.settings.waterLevelControlSettings,
          ...payload.accountData.waterLevelControlSettings,
        },
        journalWidgetSettings: {
          ...state.settings.journalWidgetSettings,
          ...(checkJournalWidgetSettingsValidity(newWidgetSettings) ? newWidgetSettings : DEFAULT_JOURNAL_WIDGET_SETTINGS),
        },
      };
    },
    toggleIsAccountSettingsExist(state: State, { payload }: PayloadAction<{ status: boolean }>) {
      state.isAccountSettingsExist = payload.status;
    },
    setAccountVideoWallSettings(state: State, { payload }: PayloadAction<{ settings: VideoWallSettings }>) {
      state.settings.videoWallSetting = { ...state.settings.videoWallSetting, ...payload.settings };
    },
    setAccountWaterLevelControlSettings(state: State, { payload }: PayloadAction<{ settings: WaterLevelControlSettings }>) {
      state.settings.waterLevelControlSettings = { ...state.settings.waterLevelControlSettings, ...payload.settings };
    },
    setAccountJournalWidgetSettings(state: State, { payload }: PayloadAction<{ settings: JournalWidgetSetting }>) {
      state.settings.journalWidgetSettings = { ...state.settings.journalWidgetSettings, ...payload.settings };
    },
    changeUITheme(state: State, { payload }: PayloadAction<{ mode: ThemeMode }>) {
      state.settings.theme = payload.mode;
    },
    toggleIsAccountTimeSettings(state: State, { payload }: PayloadAction<{ status: boolean }>) {
      state.isAccountTimeSettingsUpdated = payload.status;
    },
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
